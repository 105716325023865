<!--
 * ======================================
 * 说明： 教师端 - 评语管理
 * 作者： Silence
 * 文件： remark.vue
 * 日期： 2023/8/3 0:26
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <div class="mx-6 pl-4 pt-8">
      <el-form :inline="true" :model="formSearch" class="demo-form-inline">
        <SemesterClassSelect v-model="formSearch" @onLoadFinish="handleLoadDataInfo"  @change="handleRefresh" @refresh="handleRefresh"></SemesterClassSelect>
      </el-form>
    </div>
    <Scrollbar>
      <Table :hasAction="true" :options="handleOptions" :loadData="handleLoadData" ref="table">
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handleCheck(row)">查看</el-button>
        </template>
      </Table>
    </Scrollbar>
    <el-dialog
        title="学生画像"
        fullscreen
        custom-class="st_bg"
        :visible.sync="dialogVisible"
        :before-close="handleClose">
      <iframe :src="`/portrait/index.html?studentId=${studentId}&semesterId=${formSearch.semesterId}`" class="w-full h-full"></iframe>
    </el-dialog>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "medal",
  data() {
    return {
      options: {
        header: [],
        search: []
      },
      dialogVisible: false,
      studentId:'',
      //搜索表单结构
      formSearch: {
        semesterId: '',
        number: '',
        realName: '',
        classId: '',
      },
    };
  },
  async mounted() {

  },
  methods: {
    handleLoadDataInfo() {
      this.handleRefresh();
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/iusers/student").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}) {
      //获取分页数据结构
      this.$cloud.get("student/list", {size, page, ...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },
  
    handleCheck(row){
      this.dialogVisible = true
      this.studentId = row.id
    },
    
    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    },
    handleReset() {
      this.formSearch = {
        semesterId: '',
        number: '',
        realName: '',
        classId: ''
      }
      this.handleRefresh()
    }
  },
};
</script>
<style lang="scss">
.st_bg {
  .el-dialog__body {
    padding: 0;
    margin: 0;
    height:calc(100% - 56px);
  }
  
}
</style>
